@import '../mixins.scss';

#SkillsContainer {
  position: relative;
  background-size: 300px auto;
  border-radius: 5000px;
  width: 100%;
}

#SkillsContainer .row {
  transition: all linear 100ms;
  display: flex;
  position: relative;
  text-align: left;
  align-items: center;
  margin-bottom: 12px;
  padding: 6px 6px 12px;
  background-color: #3b9332;
  border-radius: 12px 0 0 12px;
  & * {
    transition: all linear 100ms;
    pointer-events: none;
  }
  img {
    height: 60px;
    margin-right: 8px;
  }
  .text {
    opacity: .5;
    h3 {
      margin: 0;
    }
    p {
      margin: .5em 0 0;
      font-size: .8em;
    }
  }
  &:before{
    content: '';
    position: absolute;
    transition: all linear 100ms;
    right: -20px;
    top: 0;
    width: 20px;
    height: 100%;
    background-color: transparent;
    clip-path: polygon(0 0, 0% 100%, 100% 0);
  }
  &.active {
    $ribbonColor: #227019;
    $shadow: #000;
    background-color: $ribbonColor;    
    padding: 12px;
    margin-left: -6px;
    img {
      width: 80px;
      margin-right: 16px
    }
    .text {
      opacity: 1;
      p {
        font-size: 1em;
        
      }
    }
    &:before {
      background-color: $ribbonColor;
    }
  }
}

#SkillsContainer[data-mobile="true"] .row {
  .text p {
    display: none;
  }
  &.active .text p {
    display: unset;
  }
  .text p.desktop-only {
    display: none !important;
  }
}


