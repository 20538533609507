@import '../mixins.scss';
$red-bg: rgb(207, 36, 57);
$blue-bg: rgb(47, 83, 115);
$green-bg: #3fa035;
$orange-bg: #e66741;
$white-bg: #fff;

.socials {
  display: flex;
  margin-top: 15px;
  .social-link {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    transition: all 150ms linear;
    &:hover {
      transform: scale(1.2);
    }   
  }
}

.homepage-hero {
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
  background-color: $white-bg;
  h1 {
    font-size: 4em;
    line-height: 1em;
    margin-bottom: 5px;
    span {
      display: block;
      color: #555;
      font-size: .75em;
    }
  }
  h2 {
    margin: 0;
    font-family: 'Staatliches', helvetica, sans-serif;
    color: #333;
    font-size: 1.8em;
    line-height: 1em;
  }
  @media screen and (max-width: $tablet-breakpoint) {
    padding: 0 25px;
  }
}

.homepage-section {
  min-height: 100vh;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  padding: 60px 50px;
  @media screen and (max-width: $tablet-breakpoint) {
    padding: 0 25px;
  }
  h2 {
    font-size: 2.2em;
    letter-spacing: 2px;
    margin-bottom: 15px;
    border-bottom: 3px solid rgba(255,255,255,.35);
  }
  p {
    line-height: 1.35em;
    margin-bottom: 10px;
  }
  & > p {
    max-width: 500px;
  }
  .bleed {
    $width: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;
    i {
      flex-shrink: 0;
      border-radius: 2000px 2000px 0 0;
      display: block;
      width: $width;
      margin: 0 $width 0 0;
    }
    &[data-colour="red"] i { background-color: $red-bg; }
    &[data-colour="blue"] i { background-color: $blue-bg; }
    &[data-colour="green"] i { background-color: $green-bg; }
    &[data-colour="orange"] i { background-color: $orange-bg; }
    &[data-colour="white"] i { background-color: $white-bg; }

    $columns: 300; 
    &[data-position="bottom"] {
      bottom: 0;
      @for $i from 1 through $columns {
        i:nth-child(500n + #{$i}) {
          height: (random(97) * .5) + 3 + px;
        } 
      }
    }
    &[data-position="top"] {
      top: 0;
      align-items: flex-start;
      @for $i from 1 through $columns {
        i:nth-child(500n + #{$i}) {
          height: (random(75) * .5) + 5 + px;
        }
      }
      i {
        border-radius: 0 0 2000px 2000px;
        margin: 0 0 0 $width;
      }
    }
  }
  
  &.who-i-am { 
    background-color: $red-bg;
    color: white;
    .dave-img {
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 280px;
      .container {
        border-radius: 50%;
        height: 312px;
        -webkit-tap-highlight-color: transparent;
        transform: scale(0.48);
        transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
        width: 400px;
        &:after {
          content: "";
          height: 10px;
          position: absolute;
          top: 390px;
          width: 100%;
        }
        &:hover {
          transform: scale(0.54);
        }
      }
      .container--inner {
        clip-path: path(
          "M 390,400 C 390,504.9341 304.9341,590 200,590 95.065898,590 10,504.9341 10,400 V 10 H 200 390 Z"
        );
        position: relative;
        transform-origin: 50%;
        top: -200px;
      }
      img  {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 2;
      }
      .circle {
        background-color: #961f2e;
        box-shadow: inset 0 -30px 30px rgba(0,0,0,.5);
        border-radius: 50%;
        cursor: pointer;
        height: 380px;
        left: 10px;
        pointer-events: none;
        position: absolute;
        top: 210px;
        width: 380px;
      }
      .img {
        pointer-events: none;
        position: relative;
        transform: translateY(20px) scale(1.15);
        transform-origin: 50% bottom;
        transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
      }
      .container:hover .img {
        transform: translateY(0) scale(1.2);
      }
      .dave-illustration {
        left: -3px;
        top: 140px;
        width: 300px;
      }
    }
  
   
  }
  &.what-i-do {
    background-color: $blue-bg;
    color: white;
    .desk-img {
      max-width: 400px;
    }
  }
  &.skills {
    background-color: $green-bg;
    color: white;
    height: 100vh;
    min-height: 800px;
  }
  &.brands {
    background-color: $orange-bg;
    padding: 60px 0;
    color: white;
  }
  &.say-hi {
    background-color: $white-bg;
    .site-logo {
      max-width: 130px;
      width: 100%;
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
  }
}

#BrandLogos {
  background: darken($orange-bg, 20%);
  border-radius: 8px;
  width: calc(100% - 100px);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px;
  position: relative;
  max-width: 900px;
  @media screen and (max-width: $tablet-breakpoint) {
    padding: 0 21px;
    width: 100%;
  }
  .brand {
    width: 160px;
    height: 100px;
    margin: 10px;
    background: white no-repeat 50%;
    background-size: 90% auto;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 8px;
    transition: transform 50ms linear;
    @media screen and (max-width: 500px) {
      width: 45%;
      margin: 2%;
    }
    &:hover {
      transform: scale(1.05);
    }
    &.abc { 
      background-image: url("../images/logos/abc.png");
      background-size: 70%;
    }
    &.acc { 
      background-image: url("../images/logos/acc.png");
      background-size: 70%;
    }
    &.aidacare { 
      background-image: url("../images/logos/aidacare.png");
    }
    &.businest {
      background-image: url("../images/logos/businest.jpg");
      background-size: 80%;
    }
    &.envisage {
      background-image: url("../images/logos/envisage.png");
      background-size: 80%;
    }
    &.livewire { 
      background-image: url("../images/logos/livewire.png");
      background-size: 70%;
    }
    &.oneBigSwitch { 
      background-image: url("../images/logos/one-big-switch.png");
    }
    &.petRescue { 
      background-image: url("../images/logos/petrescue.png");
    }
    &.pwc { 
      background-image: url("../images/logos/pwc.png");
      background-size: 50% auto;
    }
    &.qantas { 
      background-image: url("../images/logos/qantas.png");
    }
    &.reachOut { 
      background-image: url("../images/logos/reachout.png");
      background-size: 60%;
    }
    &.reinteractive { 
      background-image: url("../images/logos/reinteractive.png");
    }
    &.scottishPacific { 
      background-image: url("../images/logos/scottish-pacific.png");
    }
    &.ticketek { 
      background-image: url("../images/logos/ticketek.png");
    }
    &.westfield {
      background-image: url("../images/logos/westfield.png");
      background-size: 80%;
    }
  }
}