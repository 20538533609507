@import './mixins.scss';

h1,h2,h3,h4,h5,h6 {
  margin: 0;
  line-height: 1.1em;
  margin-bottom: 1em;
}

h1, h2 {
  font-family: 'Staatliches', helvetica, sans-serif;
}

h3, h4, h5, h6 {
  font-family: "Open Sans", helvetica, sans-serif;
  font-weight: bold;
}

p, li {
  font-family: "Open Sans", helvetica, sans-serif;
  font-size: 1.1em;
  margin: 0;
}

.App-LHS {
  width: 35%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.App-RHS {
  width: 65%;
  margin-left: 35%;
  position: relative;
}

@media screen and (max-width: 1100px) {
  .App-LHS {
    width: 45%;
  }
  .App-RHS {
    width: 55%;
    margin-left: 45%;
  }
};

@media screen and (max-width: $tablet-breakpoint) {
  .App-LHS, .App-RHS {
    width: 100%;
    height: auto;
    position: relative;
    margin-left: 0;
  }
};